.change-password {
  .new-account {
    &__fieldset {
      .new-account__item {
        .password-field {
          &__info {
            width: 50%;
          }
        }
      }
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__rules {
          li {
            display: flex;
          }
        }
      }
    }
  }
  .password-field {
    &__info {
      .appt-book & {
        #{$ldirection}: 65%;
        width: 25%;
      }
    }
  }
}

.appt-book-section {
  .error-messages {
    color: $color-red;
  }
}
