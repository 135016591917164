.product-full {
  &__rating {
    .p-w-r {
      .pr-review-snippet-container {
        float: none;
      }
    }
  }
}

.quiz-page-landing-update {
  .aveda-quiz-result-page {
    .product-grid {
      &__item {
        .product-brief {
          &__footer {
            min-height: 216px;
          }
        }
      }
    }
  }
}

.hair-quiz-page {
  .quiz-landing-page-formatter.show-quiz {
    .mantle-form-options-field {
      &__footer-cta {
        a {
          width: auto;
        }
      }
    }
  }
}

.trustmark_img {
  width: 20%;
  float: $rdirection;
}

.trustmark_logo {
  margin: 0 auto;
  width: 71%;
  padding: 50px 0;
}

.trustmark_content {
  width: 62%;
}

.content-block-large__disclaimer {
  margin-bottom: 15px;
}

.data_privacy_statement {
  padding: 30px;

  p {
    font-size: 13px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
  }
}
